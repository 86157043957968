import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAutosave } from 'react-autosave';
import _ from 'lodash';

import API from 'apis/API';
import Routes from 'router/Routes';
import Progress from 'components/Main/Progress';

const ManagementDetailedInformationsContext = React.createContext(null);

export const Provider = ({ children }) => {
	const history = useHistory();
	const { id } = useParams();
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';

	const [researchTasks, setResearchTasks] = useState([]);
	const [publishedInformations, setPublishedInformations] = useState([]);
	const [formData, setFormData] = useState({
		date_from: new Date(),
		date_to: new Date(),
		table1: {
			sum: {
				contract_amount: 0,
				total_fund_paid: 0,
				funds_paid: 0,
				total_expenditure_reported: 0,
				expenditure_reported: 0,
				total_approved_expenditure: 0,
				district_approved_expenditure: 0,
				funds_use_percent: 0,
			},
			research_tasks: []
		},
		table2: {
			financial: {
				contracted_funds_amount: 0,
				total_application_measures: 0,
				applied_measures: 0,
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
				funds_use: 0,
			},
			including_commercialization: {
				contracted_funds_amount: 0,
				total_application_measures: 0,
				applied_measures: 0,
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
				funds_use: 0,
			}
		},
		table3: {
			total_remuneration: { // a
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			job_contract: { // a1
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			law_contract: { // a2
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			organization: { // b
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			financing_agreements: { // c
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			wib_management: { // d
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			commercialization: {
				sum: { // e
					total_application_measures: 0,
					applied_measures: 0,
					total_expenses: 0,
					expenditure_incurred: 0,
					total_approved: 0,
					approved_expenses: 0,
				},
				agreement_costs: { // e.1
					total_application_measures: 0,
					applied_measures: 0,
					total_expenses: 0,
					expenditure_incurred: 0,
					total_approved: 0,
					approved_expenses: 0,
				},
				research_tasks: [] // e.2, e.3
			},
			stamp_duties: { // f
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			external_services: { // g
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			information_activities: { // h
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			other_direct_costs: { // i
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			indirect_costs: { // j
				total_expenses: 0,
				expenditure_incurred: 0,
				total_approved: 0,
				approved_expenses: 0,
			},
			comment: '',
		},
		table4: {
			interest: {
				together: 0,
				current_period: 0,
			},
			contractual_penalties: {
				together: 0,
				current_period: 0,
			},
		},
		justification: '',
		author_name: '',
		author_user_id: '',
		filling_place: '',
		filling_date: new Date(),
		status: 'draft',
	});
	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const [researchTasksRes, publishedRes] = await Promise.all([
				API.management.detailedInformations.getResearchTasks(),
				API.management.detailedInformations.published(id)
			]);
			setResearchTasks(researchTasksRes.data?.data || []);
			setPublishedInformations(publishedRes.data?.data || []);
	
			API.management.detailedInformations.show(id).then(res => {
				if (res.data?.code === 400) {
					toast.error('Nie znaleziono');
				}
	
				setFormData(res.data?.data);
				setLoading(false);
			});
		};
		if(id) fetchData();
	}, [id]);

	// Calculate table 2 - including_commercialization - funds_use.
	useEffect(() => {
		onChange('table2.including_commercialization.funds_use', (formData.table2.including_commercialization.total_approved / (formData.table2.including_commercialization.contracted_funds_amount || 1) || 0) * 100);
	}, [formData.table2.including_commercialization.contracted_funds_amount, formData.table2.including_commercialization.total_approved]);

	useEffect(() => {
		onChange('table2.including_commercialization', {
			...formData.table2.including_commercialization,
			...formData.table3.commercialization.sum
		});
	}, [formData.table3.commercialization.sum]);

	// Calculate table 3 - total_remuneration - a. Wynagrodzenia, w tym:
	useEffect(() => {
		calculateTotalRemuneration('total_expenses');
	}, [formData.table3.job_contract.total_expenses, formData.table3.law_contract.total_expenses]);
	// Calculate table 3 - total_remuneration - a. Wynagrodzenia, w tym:
	useEffect(() => {
		calculateTotalRemuneration('total_approved');
	}, [formData.table3.job_contract.total_approved, formData.table3.law_contract.total_approved]);

	const calculateTotalRemuneration = key => {
		let totalRemuneration = formData.table3.total_remuneration;
		totalRemuneration[key] = +formData.table3.job_contract[key] + +formData.table3.law_contract[key];
		if (key === 'total_expenses') {
			totalRemuneration.expenditure_incurred = +formData.table3.job_contract.expenditure_incurred + +formData.table3.law_contract.expenditure_incurred;
		}
		if (key === 'total_approved') {
			totalRemuneration.approved_expenses = +formData.table3.job_contract.approved_expenses + +formData.table3.law_contract.approved_expenses;
		}
		onChange('table3.total_remuneration', totalRemuneration);
	};

	const onChange = (key, value, customizer) => {
		setFormData(prev => ({ ..._.setWith(prev, key, value, customizer) }));
	};

	const onCancel = () => history.push(Routes.Management.List('detailed_informations'));

	const saveCallback = (isPublish = false) => res => {
		setSaving(false);
		if(res.data?.code === 400) {
			toast.error('Nie znaleziono');
			return onCancel();
		}
		if (res.data?.code !== 200 || !_.isEmpty(res.data?.errors)) {
			if (isPublish) {
				toast.error('Nie wszystkie pola spełniają wymagania. Popraw błędy i spróbuj ponownie.');
			} else {
				toast.success('Wersja robocza została zapisana!');
			}
			setErrors(prev => {
				prev = {};
				_.mapKeys(res.data?.errors, (value, key) => {
					_.set(prev, key, value);
				});
				return prev;
			});

			return;
		}

		setErrors(null);
		toast.success('Zadanie badawcze zostało zapisane!');
		if (isPublish) onCancel();
	};

	const onSave = () => {
		if (saving || isPreview) return;
		setSaving(true);
		(id ? API.management.detailedInformations.update : API.management.detailedInformations.store)(formData, id).then(saveCallback());
	};

	const onPublish = () => {
		if (saving || isPreview) return;
		setSaving(true);
		API.management.detailedInformations.publish(formData, id).then(saveCallback(true));
	};

	const onAutoSave = useCallback((data, creating) => {
		// When unmount component, react-autosave send request.
		// So implement to prevent saving as draft what published task in the backend.
		if (saving || isPreview || data?.status === 'published' || (!id && !creating)) return;
		setSaving(true);
		(id ? API.management.detailedInformations.update : API.management.detailedInformations.store)(data, id).then(res => {
			setSaving(false);
			if (res.data?.code !== 200 || id) return;
			history.push(Routes.Management.DetailedInformations.Edit(res.data?.data?.id));
		});
	}, []);

	useAutosave({ data: formData, onSave: onAutoSave, interval: 300000 });

	// When creating new row, save empty data and edit it.
	useEffect(() => {
		if (id) return;
		onAutoSave(formData, true);
	}, []);

	const value = {
		researchTasks,
		loading,
		publishedInformations,
		formData,
		errors,
		saving,
		isPreview,
		onChange,
		onSave,
		onPublish,
		onCancel,
	};

	return (
		<ManagementDetailedInformationsContext.Provider value={value}>
			{loading
				? <Progress status={true} />
				: children
			}
		</ManagementDetailedInformationsContext.Provider>
	);
};

export const ManagementDetailedInformationsContextProvider = withRouter(Provider);

export default ManagementDetailedInformationsContext;
