import {pl as _pl} from '@abbjs/slownie';
/* eslint-disable */

const pl = (number) => {
	const decimalStr = number.toString().split('.');
	let numberInWords = _pl(parseInt(number));
	if (decimalStr.length > 1 && decimalStr[1] != '00') {
		numberInWords += ', '+decimalStr[1]+'/100';
	}

	return numberInWords;
};

export default pl;
